import { ref, Ref, watch } from 'vue'
import { useEndpointsQuery } from './queries/useEndpointsQuery'
import { useProjectsQuery } from './queries/useProjectsQuery'
import { EndpointDtoWithImage } from '@/types/types'
import { useUserProjectDisplaySettingsQuery } from './queries/useUserProjectDisplaySettingsQuery'

export function useProjectEndpoints(projectId: Ref<string>) {

   const { data: endpoints } = useEndpointsQuery(projectId)
   const project = useProject(projectId)
   
   const { data: settings } = useUserProjectDisplaySettingsQuery()
   const anonymizeText = (text) => {
         return useAnonymizedText(text, settings)
   }

   const result = ref([] as EndpointDtoWithImage[])
   watch(
      [endpoints, projectId, project],
      () => {
         if (
            endpoints.value &&
            projectId.value &&
            project.value
         ) {
            const getPriority = (status) => {
               switch (status) {
                  case 'Missing Config':
                     return 0
                  case 'Routing':
                     return 1
                  case 'Redirect':
                     return 2
                  default:
                     return 3
               }
            }

            result.value = endpoints.value
               .map((e) => {
                  const hostName = e.hostName

                  if (project.name == 'Contoso') {
                     return {
                        ...e,
                        group: e.status == 'Redirect' ? 'redirect' : 'all',
                        statusPriority: getPriority(e.status),                     
                        requestCount: e.requestCount ? e.requestCount : 0,
                        egressMb: e.status == 'Redirect' ? 0 : (e.egressMb ? e.egressMb : 0),
                        imageUrl: `https://images.azbp.it/blueprint/performancemap/preset?projectbasename=${project.name}&subscriptionid=&endpoint=www.hempel.com&type=ttfb&presetname=endpointdashboard`,
                        label: anonymizeText(e.hostName).value
                     } as EndpointDtoWithImage
                  }

                  return {
                     ...e,
                     imageUrl: project.name && project.subscriptionId && e.status == 'Routing'
                        ? `https://images.azbp.it/blueprint/performancemap/preset?projectbasename=${project.name}&subscriptionid=${project.subscriptionId}&endpoint=${hostName}&type=ttfb&presetname=endpointdashboard`
                        : null,
                     group: e.status == 'Redirect' ? 'redirect' : 'all',
                     statusPriority: getPriority(e.status),
                     requestCount: e.requestCount ? e.requestCount : 0,
                     egressMb: e.status == 'Redirect' ? 0 : (e.egressMb ? e.egressMb : 0),
                     label: anonymizeText(e.hostName).value
                  } as EndpointDtoWithImage
               })
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
