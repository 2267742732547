import { QueryClient, useQuery } from '@tanstack/vue-query'
import { ref } from 'vue'
import QueryKeys from '@/vue-query/QueryKeys'
import { EndpointDto } from '@/api/contracts/models'
import { useProjectApi } from '../api/useProjectApi'

export function useEndpointsQuery(projectId: Ref<string>) {
   const client = useProjectApi()

   const enabled = computed(() => !!projectId.value)

   return useQuery([QueryKeys.Endpoints, projectId], () => client.listEndpoints(projectId.value), {
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
      enabled: enabled
   })
}

export function useEndpointsQueryPrefetch(
   queryClient: QueryClient,
   projectId: Ref<string>
): Promise<void> {
   const client = useProjectApi()

   return queryClient.prefetchQuery<EndpointDto[]>([QueryKeys.Endpoints, projectId], () =>
      client.listEndpoints(projectId.value)
   )
}
